import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';

$(document).on('turbolinks:load', function() {
  flatpickr("[data-datePicker]", {
    enableTime: false,
    dateFormat: "d/m/Y",
  });
  
  flatpickr("[data-dateTimePicker]", {
    enableTime: true,
    dateFormat: "d/m/Y H:i",
  });

  flatpickr("[data-dateRangePicker]", {
    mode: "range",
    enableTime: false,
    dateFormat: "d/m/Y",
    defaultDate: ["today", "today"],
    maxDate: "today",
    onChange: function(selectedDates, dateStr, instance) {
      // Replace 'to' with 'à' in the input field
      instance.element.value = dateStr.replace('to', 'à');
    }
  });

});